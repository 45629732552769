<template>
  <div>
    <Header :link-idx="1" />
    <div class="mt80 g-min-height">
      <div class="top-swiper swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
            <img :src="item.image_url" />
          </div>
        </div>
        <div class="top-pagination swiper-pagination"></div>
      </div>
      <!--  -->
      <div class="bg-f7 pb60">
        <div class="w1220">
          <!-- 菜单卡 -->
          <div class="menu-box flex-box flex-around mb30">
            <div  @click="goTeamDetail"   class="flex-box">
              <img class="icon mr30" src="@/assets/icon/icon_official.png" />
              <div class="fs26 col-3">官方推选</div>
            </div>
            <div  @click="goTeam" class="flex-box">
              <img class="icon mr30" src="@/assets/icon/icon_team.png" />
              <div class="fs26 col-3">精选团队</div>
            </div>
            <router-link to="/case" class="flex-box">
              <img class="icon mr30" src="@/assets/icon/icon_case.png" />
              <div class="fs26 col-3">中标案例</div>
            </router-link>
          </div>
          <!-- 公告 -->
          <div class="notice-box flex-box">
            <div class="icon">
              <img src="@/assets/icon/icon_notice.png" />
            </div>
            <div class="flex-1">
              <el-carousel class="notice-carousel" height="50px" direction="vertical" indicator-position="none" :loop="true" :interval="1500">
                <el-carousel-item v-for="(item, index) in notice" :key="index" class="notice-carousel-item">
                  <div class="text m-ellipsis">{{ item.content }}</div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!-- 套餐 -->
          <el-carousel class="combo-carousel" :autoplay="false" :interval="3000" type="card" height="485px" :loop="true" @change="comboChange" arrow="never">
            <el-carousel-item class="combo-carousel-item" v-for="(item, index) in meal" :key="index">
              <div class="combo-item" :class="{ active: index == comboIdx }">
                <div class="combo-body">
                  <div class="fs26 col-3 mb30">官方推选</div>
                  <div class="price">{{ item.price }}¥</div>
                  <div class="fs20 col-9 mb40">{{ item.frequency }}次不中赔{{ item.compensation_price }}¥</div>
                  <div @click="goPlace(item.id,index,comboIdx)"    class="btn">立即下单</div>
                </div>
                <div @click="goTeamDetail"   class="combo-foot fs22 col-3">更多套餐详情</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!--  -->
      <div class="intro-box w1220"   v-show=false>
        <div class="title tc mb40">双标书套餐</div>
        <div class="flex-box">
          <div class="left">
            <div class="flex-box intro">
              <div class="dot-title">特</div>
              <div class="flex-1">
                <div class="text1">成功下此套餐的用户均可享受套餐特权</div>
                <div class="fs16 col-9">安全保障 一对一优质服务 严选团队</div>
              </div>
            </div>
            <div class="flex-box intro">
              <div class="dot-title">中</div>
              <div class="flex-1">
                <div class="text1">两张标书同时拍中/拍中一张，只付一张钱</div>
                <div class="fs16 col-9">付一张钱享受两张待遇</div>
              </div>
            </div>
            <div class="flex-box intro">
              <div class="dot-title">赔</div>
              <div class="flex-1">
                <div class="text1">两张标书都未拍中 平台赔付相应赔付金</div>
                <div class="fs16 col-9">赔付款将在拍牌结束后的5天之内进行赔付</div>
              </div>
            </div>
          </div>
          <div>
            <div class="right-title">两张同拍同时拍中，一张免单</div>
            <div  @click="goPlaceTwo" class="btn">下单</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="team-title-box mb30">
        <div class="w1220 flex-box flex-center">
          <div class="line mlr15 mt15"></div>
          <div class="line mlr15 mb15"></div>
          <div class="title">精选团队</div>
          <div class="line mlr15 mt15"></div>
          <div class="line mlr15 mb15"></div>
        </div>
      </div>
      <!-- 团队列表 -->
      <div class="w1220">
        <TeamList></TeamList>
      </div>
      <!--  -->
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
import TeamList from '@/components/teamList/TeamList.vue'
import Swiper from 'swiper'
export default {
  name: 'Home',
  components: { Header, TeamList },
  data() {
    return {
      token:'',
      banner: [],
      notice: [],
      meal: [],
      teamId: 3,
      phoneNumber: '400-820-7735',
      comboIdx: 0
    }
  },
  created() {
    this.token = this.$storage.get('token');
  },
  mounted() {
    this.getBanner()
    this.getNotice()
    this.getMeal()
    /* eslint-disable no-new */
    new Swiper('.top-swiper', {
      loop: true,
      pagination: '.top-pagination',
      autoplay: false
    })
    scrollTo({
      top: 0
    })
  },
  methods: {
    comboChange(e) {
      this.comboIdx = e
    },
    goTeam(){
        this.$router.push({ path: '/team'})
    },
    goTeamDetail(){
         this.$router.push({ path: '/teamDetail',query:{id:this.teamId} })
    },
    getBanner() {
      this.$http
        .get('/api/team/bannerlist', {
          action: 'web'
        })
        .then(res => {
          this.banner = res.data.data
        })
    },
    getNotice() {
      this.$http
        .get('/api/team/notice', {
          action: 'web'
        })
        .then(res => {
          this.notice = res.data.data
        })
    },
    getMeal() {
      this.$http
        .get('/api/team/meal', {
          action: 'web'
        })
        .then(res => {
          this.meal = res.data.data.list
          this.teamId = res.data.data.id
          this.phoneNumber = res.data.data.phoneNumber
        })
    },
    goPlace(id,index,comboIdx){
        if(index!=comboIdx){
            return false;
        }
        if(!this.token){
          this.$router.push('/login');
        }
         this.$router.push({ path: '/placeOrder',query:{id:id} })
    },
    goPlaceTwo(){
        if(!this.token){
          this.$router.push('/login');
        }
        this.$router.push({ path: '/placeOrderSecond' })    
    }
  }
}
</script>
<style lang="less" scoped>
.top-swiper {
  height: 600px;
  img {
    display: block;
    width: 100%;
    height: 600px;
  }
  .top-pagination {
    bottom: 100px;
  }
}
.menu-box {
  position: relative;
  margin-top: -75px;
  height: 150px;
  background: #ffffff;
  border-radius: 80px;
  z-index: 5;
  .flex-box {
    cursor: pointer;
  }
}
.notice-box {
  margin: 0 0 50px;
  height: 50px;
  .icon {
    padding: 13px 0 0;
    width: 56px;
    height: 50px;
    background: #f6e65f;
    img {
      margin: 0 auto;
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}
.notice-carousel {
  position: relative;
  background-color: #fff;
  height: 50px;
  overflow: hidden;
  .notice-carousel-item {
    position: absolute;
    .text {
      padding: 0 20px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      color: #333;
    }
  }
}
.combo-carousel {
  position: relative;
  overflow: hidden;
  height: 485px;
  .combo-carousel-item {
    position: absolute;
  }

  .combo-item {
    margin: 0 auto;
    max-width: 460px;
    max-height: 610px;
    text-align: center;
    .combo-body {
      padding: 35px 0 0;
      margin: 0 auto;
      width: 360px;
      height: 364px;
      background: #ffffff;
      border-radius: 10px;
      .price {
        margin-bottom: 30px;
        font-size: 40px;
        color: #f9c764;
      }
      .btn {
        margin: 0 auto;
        width: 198px;
        height: 60px;
        background: #e0e0e0;
        border-radius: 30px;
        font-size: 20px;
        color: #ffffff;
        line-height: 60px;
      }
    }
    .combo-foot {
      display: none;
      margin: 30px auto 0;
      width: 360px;
      height: 90px;
      background: #ffffff;
      border-radius: 10px;
      line-height: 90px;
    }
  }
  .active {
    .combo-foot {
      display: block;
    }
    .combo-body {
      .btn {
        background: var(--yellow);
        color: #333;
      }
    }
  }
}
.intro-box {
  padding: 60px 0 40px;
  .title {
    width: 192px;
    height: 50px;
    background: #f6e65f;
    border-radius: 10px;
    font-size: 22px;
    color: #333333;
    line-height: 50px;
  }
  .left {
    margin-right: 190px;
    width: 500px;
    .intro {
      padding: 40px 0;
      .dot-title {
        margin-right: 20px;
        width: 68px;
        height: 68px;
        border: 1px solid #ff1f5d;
        font-size: 30px;
        font-weight: bold;
        color: #ff1f5d;
        line-height: 68px;
        border-radius: 50%;
        text-align: center;
      }
      .text1 {
        margin-bottom: 20px;
        font-size: 20px;
        color: #ff1f5d;
      }
    }
    .intro + .intro {
      border-top: 1px solid #efefef;
    }
  }
  .right-title {
    margin-bottom: 145px;
    font-size: 40px;
    font-weight: bold;
    color: #0051b4;
  }
  .btn {
    width: 198px;
    height: 60px;
    background: #f6e65f;
    border-radius: 30px 30px 30px 30px;
    font-size: 20px;
    color: #333333;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
  }
}
.team-title-box {
  background-color: var(--yellow);
  .w1220 {
    height: 130px;
    .line {
      width: 1px;
      height: 62px;
      background-color: #333333;
      transform: rotate(15deg);
    }
    .title {
      margin: 0 35px;
      font-size: 46px;
      font-weight: bold;
      color: #333333;
    }
  }
}
</style>

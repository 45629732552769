<template>
  <div class="footer">
    <div class="w1220 col-3">
      <div class="flex-box mb45">
        <router-link to="/">
          <img class="logo" src="@/assets/images/logoteam.png" alt="Logo" />
        </router-link>
        <div class="flex-1 flex-box flex-end fs20">
          <!---<router-link to="/" class="link">拍牌</router-link>-->
          <router-link to="/team" class="link">团队</router-link>
          <router-link to="/profile" class="link">我的</router-link>
        </div>
      </div>
      <div class="flex-box mb70">
        <!---<div class="flex-1 fs18">「神枪手拍牌」平台直接对接拍手团队，去除中间环节，不中即赔、先行赔付、平台担保。</div>-->
        <div class="fs24">{{kefu}}</div>
      </div>
      <div class="flex-box flex-center">
        <img class="icon-beian" src="@/assets/icon/icon_beian.png" alt="" />
        <a href="https://beian.miit.gov.cn/publish/query/indexFirst.action" class="fs14 ml10">Copyright@2019-2022 臻桩网络科技（上海）有限公司 沪ICP备2022030005号-1 </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return{
      kefu:'400-820-7735',
      content:'',
      company:'',
      beian:''
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  padding: 45px 0 0;
  height: 300px;
  background: #fafafa;
  .logo {
    display: block;
    width: 158px;
    height: 61px;
  }
  .link {
    margin-left: 60px;
    cursor: pointer;
  }
}
</style>

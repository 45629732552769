<template>
  <div class="header bg-fc">
    <div class="w1220 flex-box">
      <router-link to="/">
        <img class="logo" src="@/assets/images/logoteam.png" alt="Logo" />
      </router-link>
      <div class="flex-1 flex-box flex-end col-3">
        <!--<router-link to="/" class="link" :class="{ active: linkIdx == 1 }">拍牌</router-link>-->
        <router-link to="/team" class="link" :class="{ active: linkIdx == 2 }">团队</router-link>
        <router-link to="/profile" class="link" :class="{ active: linkIdx == 3 }">我的</router-link>
        <div class="kefu flex-box">
          <img class="icon-kefu" src="@/assets/icon/icon_service.png" />
          <div class="ml10">{{kefu}}</div>
        </div>
        <router-link  v-show="!islogin"  to="/login" class="login">登录</router-link>
        <img class="img-head" @click="goprofile" v-show="islogin"  :src="userinfo.headimgurl" alt="" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Header',
  props: {
    linkIdx: Number
  },
  data() {
    return{
     islogin:0,
     token:'',
     userinfo:[],
     kefu:'400-820-7735'
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(this.token){
       this.getUserInfo()
     }
  },
  mounted(){

  },
  methods: {
    goprofile(){
       this.$router.push('/profile');
    },
   getTeach(){
        var that =  this;
        var params = {action:'pc'};
        that.$http.get("/api/team/meal", {
          params
        }).then((res) => {
            that.kefu = res.data.data.phoneNumber
        });        
     },
    getUserInfo(){
        var params = {token:this.token};
        this.$http.get("/api/user/profile", {
          params
        }).then((res) => {
           if(res.data.code==1){
              this.islogin = 1;
              this.userinfo = res.data.data;
           }else{
              this.islogin = 0;
               this.$storage.set('token', '');
           }
        });         
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  .w1220 {
    height: 80px;
    font-size: 20px;
    line-height: 80px;
    .logo {
      display: block;
      width: 157px;
      height: 60.5px;
    }
    .link {
      padding: 0 60px;
    }
    .login {
      cursor: pointer;
    }
    .kefu {
      cursor: pointer;
      margin: 0 80px 0 30px;
    }
    .img-head {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}
.active {
  background: #f6e65f;
}
</style>
